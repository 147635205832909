<template>
    <base-section id="features" class="secondary" space="0">
        <v-responsive class="mx-auto" max-width="1350">
            <v-container fluid>
                <v-row>
                    <v-col
                        v-for="card in cards"
                        :key="card.title"
                        cols="12"
                        sm="4"
                        md="3"
                    >
                        <base-info-card
                            align="center"
                            dark
                            v-bind="card"
                            color="secondary lighten-3"
                            text-class="white--text"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-responsive>
    </base-section>
</template>

<script>
export default {
    name: "SectionFeatures",

    data: () => ({
        cards: [
            {
                icon: "mdi-apps",
                title: "Software Creation",
                text:
                    "We use cutting edge technology to design and implement new software.  We believe that software should fix problems and help you work more efficiently",
            },
            {
                icon: "mdi-handshake",
                title: "Community Partnerships",
                text:
                    "Pacific Screening has build long standing relationships base on trust and integrity.",
            },
            {
                icon: "mdi-security",
                title: "Security",
                text:
                    "We implement a disciplined security strategy securing our data with multiple levels of protection including networking firewalls, strict password rotation rules, and data encryption.",
            },
            {
                icon: "mdi-face-agent",
                title: "Customer Service",
                text:
                    "We strive to be the gold standard when it comes to customer service. We work to be prompt and curious at all times. We work with you to solve problems.",
            },
        ],
    }),
};
</script>
